
import { defineComponent } from "vue";
import { submitReport, getPeopleListcopy } from "@/api/long-insure";
import global from "@/utils/global";
import dayjs from "dayjs";
import user from "@/utils/user";
import caseNotice from "@/components/dialog/case-notice.vue";
import store from "@/store/index";
import uploadVideo from "@/components/upload-video/index.vue";
export default defineComponent({
  name: "LongInsureReportAdd",
  components: {
    caseNotice,
    uploadVideo
  },
  created() {
    this.getPeopleList();
  },
  data() {
    return {
      Authorization: "Bearer" + " " + global.token,
      uploadAction:
        process.env.VUE_APP_BASE_URL +
        "/rest/period-insur/serve-company/upload", // 上传路径
      searchType: "",
      date: "",
      place: "",
      after: "",
      phone: "",
      accidentType: "2",
      fileSize: "",
      fileUrl: "",
      isfileLengt: true,
      hurtStatus: "1",
      hospitalStatus: "1",
      outDangerPhone: "",
      outDangerUserId: "",
      outDangerUserName: "",
      userList: [] as any,
      fileList: [] as any,
      visitHospital: "",
      userId: "",
      showNotice: true,
      insurestartdate: ""
    };
  },
  activated() {
    this.$nextTick(() => {
      this.showNotice = true;
    });
  },
  mounted() {},
  methods: {
    handleDisableDate(time: any) {
      console.log(this.insurestartdate, "huagfuhyffh");
      console.log(time, "111");
      if (!this.insurestartdate) {
        return true;
      } else {
        const date = dayjs(this.insurestartdate);
        const previousDate = date
          .subtract(1, "day")
          .format("YYYY-MM-DD HH:mm:ss");
        let start = new Date(previousDate).getTime();
        let end = new Date().getTime();
        return !(
          new Date(time).getTime() >= start && new Date(time).getTime() < end
        );
      }
    },
    // 出险人列表
    async getPeopleList() {
      const result = await getPeopleListcopy({
        pageNum: 1,
        pageSize: 10000,
        policyStatus: "",
        searchText: ""
      });
      console.log(result, "result");
      this.userList = result.list;
    },
    // fileBeforeUpload (file:any) {},
    async fileSuccess(
      response: {
        code: number;
        msg: string;
        data: { fileSize: string; fileUrl: string };
      },
      file: any,
      fileList: any
    ) {
      var url = URL.createObjectURL(fileList[fileList.length - 1].raw);
      var audioElement = new Audio(url);
      var duration = parseInt(audioElement.duration + "");
      await audioElement.addEventListener("loadedmetadata", () => {
        console.log(parseInt(audioElement.duration + ""), "时长为秒，取整"); // 时长为秒，取整
        const fileLength = parseInt(audioElement.duration + "");
        if (fileList.length > 3) {
          this.$message.error("最多上传三个视频");
          return;
        }
        if (fileLength < 10 && fileLength > 100) {
          this.$message.error("视频不可小于10秒或大于100秒");
          this.isfileLengt = false;
          return;
        }
        if (file.size / 1024 / 1024 > 50) {
          this.$message.error("视频不可大于50M");
          this.isfileLengt = false;
          return;
        }
        if (response.code === 500) {
          this.$message.error(response.msg);
          return;
        }
        if (file.raw.type !== "video/mp4") {
          this.$message.error("请上传视频");
          return;
        }
        console.log(file, "file");
        this.fileList.push({
          name: file.raw.name,
          url: response.data.fileUrl,
          fileSize: response.data.fileSize,
          fileType: "video",
          fileUrl: response.data.fileUrl,
          id: new Date().getTime()
        });
        this.$message.success("上传成功");
      });
    },
    fileEerror() {
      this.$message.error("文件上传失败");
    },
    // 删除文件
    fileRemove(file: any, uploadFiles: any) {
      console.log(file, uploadFiles, "uploadFiles");
      this.fileList = this.fileList.filter(
        (item: { id: number }) => item.id !== file.id
      );
    },
    // 选择出险人
    onSelectDangerous(event: any) {
      const obj = this.userList.filter((item: any) => item.id === event)[0];
      this.userId = obj.userId;
      this.insurestartdate = obj.insurStartTime;
    },
    // 提交
    async onModifyCode() {
      console.log(this.fileList, "fileList");
      const userInfo = this.userList.filter(
        (item: { id: string }) => item.id === this.outDangerUserId
      );
      console.log(userInfo, "userInfo");
      if (!this.outDangerUserId) {
        this.$message.error("请选择出险人");
        return;
      }
      if (!this.date) {
        this.$message.error("请选择日期");
        return;
      }
      if (!this.place) {
        this.$message.error("请输入出现地点");
        return;
      }
      if (!this.visitHospital) {
        this.$message.error("请填写就诊医院");
        return;
      }
      if (!this.after) {
        this.$message.error("请输入详细事故经过");
        return;
      }
      if (!this.phone) {
        this.$message.error("请输入报案人手机号");
        return;
      }
      const result = await submitReport({
        accidentDetails: this.after,
        accidentType: this.accidentType,
        hurtStatus: this.hurtStatus,
        hospitalStatus: this.hospitalStatus,
        outDangerDate: this.date,
        outDangerPhone: this.outDangerPhone,
        outDangerPlace: this.place,
        outDangerUserId: this.userId,
        outDangerUserName: userInfo[0].userName,
        visitHospital: this.visitHospital,
        params: {
          photos: this.fileList
        },
        reportPhone: this.phone,
        recordId: this.outDangerUserId
      });
      console.log(result, "result");
      await this.$message.success("提交成功");
      store.commit("delPage", {
        page: "/long-insure-report-add",
        name: "报案理赔"
      });
      await this.$router.push({
        path: "/long-insure-report"
      });
    }
  }
});
