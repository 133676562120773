
import { createInsureCase, insureUserGuarantee } from "@/api/insure-deduction";
import { defineComponent } from "vue";
import global from "@/utils/global";
import { dateFormat } from "@/utils/util";
import uploadVideo from "@/components/upload-video/index.vue";
import dayjs from "dayjs";
export default defineComponent({
  name: "InsureTeamReportAdd",
  components: {
    uploadVideo
  },
  created() {
    this.getInsureUserGuarantee();
  },
  data() {
    return {
      Authorization: "Bearer" + " " + global.token,
      uploadAction:
        process.env.VUE_APP_BASE_URL +
        "/rest/period-insur/insur-period-report-case/upload", // 上传路径
      searchType: "",
      date: "",
      place: "",
      after: "",
      phone: "",
      accidentType: "2",
      hurtStatus: "1",
      fileSize: "",
      fileUrl: "",
      isfileLengt: true,
      outDangerPhone: "",
      outDangerUserId: "",
      outDangerUserName: "",
      userList: [] as any,
      fileList: [] as any,
      idCardNo: "",
      visitHospital: "", // 就诊医院
      userId: "",
      tbdate: ""
    };
  },
  methods: {
    handleDisableDate(time: any) {
      console.log(this.tbdate, "huagfuhyffh");
      console.log(time, "111");

      if (!this.tbdate) {
        return true;
      } else {
        const date = dayjs(this.tbdate);
        const nextDay: any = date.add(1, "day").format("YYYY-MM-DD HH:mm:ss");
        const previousDate = date
          .subtract(1, "day")
          .format("YYYY-MM-DD HH:mm:ss");
        let start = new Date(previousDate).getTime();
        let end = new Date(nextDay).getTime();
        return !(
          new Date(time).getTime() >= start && new Date(time).getTime() < end
        );
      }
    },
    // 选择出险人
    onChangeSelect(event: any) {
      const obj = this.userList.filter((item: any) => item.id === event)[0];
      console.log(obj, "触发了");
      this.outDangerPhone = obj.phone;
      this.idCardNo = obj.idCardNo;
      this.userId = obj.userId;
      this.tbdate = obj.createTime;
      console.log(this.userId, "this.userId = obj.userId");
    },
    // 选择时间
    onChangeDate(date: string) {
      console.log(date, "date");
      this.getInsureUserGuarantee();
    },
    // 出险人列表
    async getInsureUserGuarantee() {
      const result = await insureUserGuarantee({
        insureCompanyType: "insure_type_tyrb",
        insureDay: "",
        isQueryOutDanger: 1
      });
      console.log(result, "result");
      this.userList = result.list;
    },
    // fileBeforeUpload (file:any) {},
    async fileSuccess(
      response: {
        code: number;
        msg: string;
        data: { fileSize: string; fileUrl: string };
      },
      file: any,
      fileList: any
    ) {
      var url = URL.createObjectURL(fileList[fileList.length - 1].raw);
      var audioElement = new Audio(url);
      var duration = parseInt(audioElement.duration + "");
      await audioElement.addEventListener("loadedmetadata", () => {
        console.log(parseInt(audioElement.duration + ""), "时长为秒，取整"); // 时长为秒，取整
        const fileLength = parseInt(audioElement.duration + "");
        if (fileList.length > 3) {
          this.$message.error("最多上传三个视频");
          return;
        }
        if (fileLength < 10 && fileLength > 100) {
          this.$message.error("视频不可小于10秒或大于100秒");
          this.isfileLengt = false;
          return;
        }
        if (file.size / 1024 / 1024 > 50) {
          this.$message.error("视频不可大于50M");
          this.isfileLengt = false;
          return;
        }
        if (response.code === 500) {
          this.$message.error(response.msg);
          return;
        }
        if (file.raw.type !== "video/mp4") {
          this.$message.error("请上传视频");
          return;
        }
        console.log(file, "file");
        this.fileList.push({
          name: file.raw.name,
          url: response.data.fileUrl,
          fileSize: response.data.fileSize,
          fileType: "video",
          fileUrl: response.data.fileUrl,
          id: new Date().getTime()
        });
        this.$message.success("上传成功");
        console.log(this.fileList, "fileList文件列表");
      });
    },
    fileEerror() {
      this.$message.error("文件上传失败");
    },
    // 删除文件
    fileRemove(file: any, uploadFiles: any) {
      console.log(file, uploadFiles, "uploadFiles");
      this.fileList = this.fileList.filter(
        (item: { id: number }) => item.id !== file.id
      );
    },
    // 提交
    async onModifyCode() {
      console.log(this.fileList, "fileList");
      const userInfo = this.userList.filter(
        (item: { id: string }) => item.id === this.outDangerUserId
      );
      console.log(userInfo, "userInfo");
      if (this.fileList.length === 0) {
        this.$message.error("请至少上传一个视频");
        return;
      }
      if (!this.outDangerUserId) {
        this.$message.error("请选择出险人");
        return;
      }
      if (!this.outDangerPhone) {
        this.$message.error("请输入出险人电话");
        return;
      }
      if (!this.date) {
        this.$message.error("请选择日期");
        return;
      }
      if (!this.place) {
        this.$message.error("请输入出现地点");
        return;
      }
      if (!this.visitHospital) {
        this.$message.error("请输入就诊医院");
        return;
      }
      if (!this.after) {
        this.$message.error("请输入详细事故经过");
        return;
      }
      if (!this.phone) {
        this.$message.error("请输入报案人手机号");
        return;
      }
      // 就诊医院  无 严重伤情(1:是,2:不是)
      const result = await createInsureCase({
        idCardNo: this.idCardNo, // 出险人身份证号码
        accidentDetails: this.after, // 案件经过
        accidentType: this.accidentType,
        outDangerDate: this.date, // 出险日期
        outDangerPhone: this.outDangerPhone, // 出险人手机号
        outDangerPlace: this.place,
        outDangerUserId: this.userId, // 出险人id
        outDangerUserName: userInfo[0].userName, // 出险人姓名
        params: {
          photos: this.fileList // 附件
        },
        hurtStatus: this.hurtStatus,
        visitHospital: this.visitHospital,
        reportPhone: this.phone, // 报案人手机号
        recordId: this.outDangerUserId
      });
      console.log(result, "result");
      await this.$message.success("提交成功");
      await this.$router.push({
        path: "/insure-team-report"
      });
    }
  }
});
