
import { defineComponent } from 'vue'
import global from '@/utils/global'
import {
 applyOccupation,
    getDispatchList,
    cancelDispatchExamine,
    deleteDispatchExamine,
    addDispatch,
    getCompanyList
} from '@/api/long-insure'

export default defineComponent({
    props: {
        show: {
            type: Boolean,
            default: false
        },
        // 申请新工种时传入
        curCompanyId: {
            type: [Number, String]
        },
        id: {
            type: [Number, String]
        },
        // add：添加被派遣单位 apply：申请新工种
        addType: {
            type: String,
            default: 'add'
        }
    },
    data() {
        return {
            showDialog: false,
            isfileLengt: true,
            companyList: [] as any, // 所有单位
            Authorization: 'Bearer' + ' ' + global.token,
            uploadAction: process.env.VUE_APP_BASE_URL + '/rest/period-insur/serve-company/upload',
            fileSize: '',
            fileUrl: '',
            companyId: '' as any,
            disabled: false
        }
    },
    watch: {
        async show(newval) {
            this.showDialog = newval
            if (newval) {
                await this.getCompanyList()
                this.setDefaultCompany()
            }
        },
        showDialog(newval) {
            this.$emit('update:show', newval)
            if (!newval) {
                this.fileUrl = ''
                this.fileSize = ''
            }
        }
    },
    computed: {
        titleName() {
            return this.addType === 'apply' ? '申请新工种' : '新增被派遣单位'
        }
    },
    async created() {
        await this.getCompanyList()
    },
    methods: {
        setDefaultCompany() {
            if (this.addType === 'apply') {
                this.companyId = parseInt(this.curCompanyId as string)
                this.disabled = true
            } else {
                this.companyId = ''
                this.disabled = false
            }
        },
        // 获取所有单位列表
        async getCompanyList() {
            const result = await getCompanyList()
            console.log(result, 'result获取所有单位列表')
            this.companyList = result.list
        },
        async fileBeforeUpload(file: any) {
            var url = URL.createObjectURL(file)
            var audioElement = new Audio(url)
            var duration = parseInt(audioElement.duration + '')
            audioElement.addEventListener('loadedmetadata', () => {
                console.log(parseInt(audioElement.duration + ''), '时长为秒，取整')// 时长为秒，取整
                const fileLength = parseInt(audioElement.duration + '')
                if (fileLength < 10 && fileLength > 60) {
                    this.$message.error('视频不可小于10秒或大于60秒')
                    this.isfileLengt = false
                    return
                }
                if ((file.size / 1024 / 1024) > 50) {
                    this.$message.error('视频不可大于50M')
                    this.isfileLengt = false
                }
            })
        },
        fileSuccess(response: { code: number, msg: string, data: { fileSize: string, fileUrl: string } }, file: { raw: { name: string, type: string } }) {
            console.log(file.raw, 'file.raw')
            if (response.code === 500) {
                this.$message.error(response.msg)
                return
            }
            if (file.raw.type !== 'video/mp4') {
                this.$message.error('请上传视频')
                return
            }
            // if (!this.isfileLengt) {
            //     this.$message.error('视频不可小于10秒或大于100秒')
            //     return
            // }
            this.fileSize = response.data.fileSize
            this.fileUrl = response.data.fileUrl
            console.log(file, 'file', file.raw.type)
            this.$message.success('上传成功')
        },
        fileEerror() {
            this.$message.error('文件上传失败')
        },
        // 被派遣单位
        async addDispatchUnit() {
            console.log('添加派遣单位')
            console.log(this.companyId, 'companyId')
            if (!this.fileUrl) {
                this.$message.error('请至少上传一个视频')
                return
            }
            console.log(2121, this.fileUrl)
            const result = await addDispatch({
                serveCompanyId: Number(this.companyId),
                params: {
                    // video: this.fileList[0].fileUrl
                    video: this.fileUrl
                }
            })
            this.showDialog = false
            this.$emit('success')
            this.$message.success('提交被派遣单位成功')
        },
        async onSubmit() {
            if (this.addType === 'apply') {
                // 申请新工种
                if (!this.fileUrl) {
                    this.$message.error('请至少上传一个视频')
                    return
                }
                const result = await applyOccupation({
                    periodServeCompanyId: Number(this.id),
                    params: {
                        // video: this.fileList[0].fileUrl
                        videos: [this.fileUrl]
                    }
                })
                this.showDialog = false
                this.$emit('success')
                this.$message.success('申请新工种成功')
            } else {
                // 添加被派遣单位
                this.addDispatchUnit()
            }
            this.$emit('success')
        }
    }
})

